tr, th, thead, tbody, td {
  color: #C8C8C8;
}

.claim-btn {
  background: transparent !important;
  border: 1px solid #C8C8C8 !important;
  padding: 5px 25px !important;
}

.h3-title {
  font-weight: 900;
  color: #C8C8C8 !important;
}

.contract-address {
  color: #C8C8C8 !important;
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0 20px 0;
}