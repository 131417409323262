.logo {
  width: 200px;
}

.logout-btn {
  color: #C8C8C8 !important;
  border-radius: 6px !important;
  border: 1px solid #C8C8C8 !important;
  font-size: 16px !important;
  padding: 5px 20px !important;
  background: transparent !important;
  cursor: pointer !important;
}